import request from '@/utils/request'

// 促销管理  经销商
export function listPromotion(query) {
    return request({
        url: '/promotion/listPromotion',
        method: 'get',
        params: query
    })
}

// 716促销定时
export function CNAugustPromotion(query) {
    return request({
        url: 'order/CNAugustPromotion',
        method: 'get',
        params: query
    })
}
