<template>
  <div class="product-selector">
    <el-table
      :key="tableKey"
      v-loading="loading"
      :data="data"
      height="500"
      :row-key="getRowKeys"
      fit
      border
      stripe
      highlight-current-row
      default-expand-all
      :expand-row-keys="expands"
      style="width:100%;margin-bottom:20px;"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      :span-method="arraySpanMethod"
    >
      <el-table-column label="类别" prop="catalog" width="120" header-align="center" />
      <el-table-column label="商品编码" prop="code" width="140" align="center" />
<!--      <el-table-column v-if="isAgent===false" label="详情信息" width="100" prop="img" align="center">-->
<!--      <template slot-scope="{row}">-->
<!--        <div class="icon-warp" >-->
<!--          <i style="font-size:20px;color: orange;cursor: pointer" class="el-icon-picture-outline" @click="Picturedetails(row)"></i>-->
<!--        </div>-->
<!--      </template>-->
<!--      </el-table-column>-->
      <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
      <el-table-column :label="exchange ? '换货价格' : '价格'" prop="price" width="80" align="center">
        <template slot-scope="{row}">
          <span>{{ exchange ? row.proPrice : row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="exchange ? '换货积分' : '消费指数'" prop="pv" width="80" align="center">
        <template slot-scope="{row}">
          <span>{{ exchange ? row.point : row.pv }}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用库存" prop="stock" width="80" align="center">
        <template slot-scope="{row}">
          <span v-if="row.stock <= 0" style="color:#F25151;">{{ row.stockTip || '库存不足' }}</span>
          <span v-if="row.stock > 0 && row.stock <= 100">{{ row.stock }}</span>
          <span v-if="row.stock > 100">充足</span>
        </template>
      </el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="120" align="center">
        <template slot-scope="{row}">
          <el-input-number
            v-model="row.quantity"
            size="mini"
            :min="0"
            @change="handleQuantityChange(row)"
            style="width:100px;"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible1">
      <div style="width: 200px;height: 200px;margin:auto auto">
        <img src="" alt="">
      </div>
      <el-row>
        <el-form label-width="130px" >
          <el-col :span="12">
            <el-form-item label="产品编码：">
              {{}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称：">
              {{}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价：">
              {{}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="消费指数：">
              {{}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格：">
              {{}}
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-button type="primary" style="margin-left: 90%" size="mini" @click="dialogVisible1 = false">关闭</el-button>
    </el-dialog>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" center>
      <el-table
        :key="skuTableKey"
        :data="skuList"
        fit
        stripe
        border
        highlight-current-row
        default-expand-all
        row-key="id"
        style="width:100%;margin-bottom:20px;"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :span-method="skuSpanMethod"
      >
        <el-table-column label="商品名称" prop="name" show-overflow-tooltip />
        <el-table-column label="商品编码" prop="code" />
        <el-table-column label="购买数量" prop="quantity" width="120" align="center">
        <template slot-scope="{row}">
          <el-input-number v-model="row.quantity" size="mini" @change="handleSkuQuantityChange(row)" :min="0" :max="skuQuantity" style="width:100px;" />
        </template>
      </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProductSelector',
  props: {
    value: Array,
    data: Array,
    loading: Boolean,
    exchange: Boolean
  },
  computed:{
    ...mapGetters([
      'isAgent',
    ]),
  },
  data() {
    return {
      dialogVisible1:false,
      selectedValue: this.value || [],
      tableKey: 1,
      expands: [],
      getRowKeys(row) {
        return row.id
      },
      skuTableKey: 2,
      skuList: [],
      dialogVisible: false,
      dialogTitle: '',
      skuQuantity: 0,
      testData: [
        { id: 100, catalog: '产品套组', code: '', name: '', price: 0, pv: 0, stock: 0, quantity: 0, children: [
          { id: 101, catalog: '产品套组', code: 'CPTZ1', name: '产品套组1', price: 100.99, pv: 100, stock: 10000, quantity: 0 , products: [
            { id: 1, name: '家居百货', children: [
              { id: 211, catalog: '家居百货', code: 'JJBH1-S', name: '家居百货1-S', price: 100, pv: 100, stock: 100, quantity: 0 },
              { id: 212, catalog: '家居百货', code: 'JJBH1-M', name: '家居百货1-M', price: 100, pv: 100, stock: 100, quantity: 0 },
              { id: 213, catalog: '家居百货', code: 'JJBH1-L', name: '家居百货1-L', price: 100, pv: 100, stock: 100, quantity: 0 }
            ]},
            { id: 2, name: '服装服饰', children: [
              { id: 311, catalog: '服装服饰', code: 'FZFS1-S', name: '服装服饰1-S', price: 100, pv: 100, stock: 100, quantity: 0 },
              { id: 312, catalog: '服装服饰', code: 'FZFS1-M', name: '服装服饰1-M', price: 100, pv: 100, stock: 100, quantity: 0 },
              { id: 313, catalog: '服装服饰', code: 'FZFS1-L', name: '服装服饰1-L', price: 100, pv: 100, stock: 100, quantity: 0 }
            ]}
          ]},
          { id: 102, catalog: '产品套组', code: 'CPTZ2', name: '产品套组2', price: 2000.99, pv: 2000.99, stock: 100, quantity: 0 },
          { id: 103, catalog: '产品套组', code: 'CPTZ3', name: '产品套组3产品套组3产品套组3产品套组3产品套组3', price: 30000.99, pv: 30000.99, stock: 100, quantity: 0 },
          { id: 104, catalog: '产品套组', code: 'CPTZ4', name: '产品套组4', price: 400, pv: 400, stock: 100, quantity: 0 }
        ]},
        { id: 200, catalog: '家居百货', code: '', name: '', price: 0, pv: 0, stock: 0, quantity: 0, children: [
          { id: 201, catalog: '家居百货', code: 'JJBH1', name: '家居百货1', price: 100, pv: 100, stock: 100, quantity: 0 },
          { id: 202, catalog: '家居百货', code: 'JJBH2', name: '家居百货2', price: 200, pv: 200, stock: 100, quantity: 0 },
          { id: 23, catalog: '家居百货', code: 'JJBH3', name: '家居百货3', price: 300, pv: 300, stock: 100, quantity: 0 },
          { id: 204, catalog: '家居百货', code: 'JJBH4', name: '家居百货4', price: 400, pv: 400, stock: 100, quantity: 0 }
        ]},
        { id: 300, catalog: '服装服饰', code: '', name: '', price: 0, pv: 0, stock: 0, quantity: 0, children: [
          { id: 301, catalog: '服装服饰', code: 'FZFS1', name: '服装服饰1', price: 100, pv: 100, stock: 100, quantity: 0 },
          { id: 302, catalog: '服装服饰', code: 'FZFS2', name: '服装服饰2', price: 200, pv: 200, stock: 100, quantity: 0 },
          { id: 303, catalog: '服装服饰', code: 'FZFS3', name: '服装服饰3', price: 300, pv: 300, stock: 100, quantity: 0 },
          { id: 304, catalog: '服装服饰', code: 'FZFS4', name: '服装服饰4', price: 400, pv: 400, stock: 100, quantity: 0 }
        ]},
        { id: 400, catalog: '健康食品', code: '', name: '', price: 0, pv: 0, stock: 0, quantity: 0, children: [
          { id: 401, catalog: '健康食品', code: 'JKSP1', name: '健康食品1', price: 100, pv: 100, stock: 100, quantity: 0 },
          { id: 402, catalog: '健康食品', code: 'JKSP2', name: '健康食品2', price: 200, pv: 200, stock: 100, quantity: 0 },
          { id: 403, catalog: '健康食品', code: 'JKSP3', name: '健康食品3', price: 300, pv: 300, stock: 100, quantity: 0 },
          { id: 404, catalog: '健康食品', code: 'JKSP4', name: '健康食品4', price: 400, pv: 400, stock: 100, quantity: 0 }
        ]}
      ]
    }
  },
  mounted() {
    console.log(this.isAgent)
    // this.expands.push(this.list[0].id)
  },
  methods: {
    arraySpanMethod({ row, columnIndex }) {
      if (row.children && row.children.length) {
        if (columnIndex === 0) {
          return [1, 7]
        } else {
          return [0, 0]
        }
      }
    },
    handleQuantityChange(row) {
      const index = this.selectedValue.findIndex(ele => ele.id === row.id)
      if (index >= 0) {
        this.selectedValue[index].quantity = row.quantity
      } else {
        this.selectedValue.push({ ...row })
      }
      this.$emit('input', this.selectedValue.filter(ele => ele.quantity > 0))
      if (row.quantity && row.products && row.products.length) {
        this.selectSkus(row.id)
      }
    },
    selectSkus(id) {
      const row = this.selectedValue.find(ele => ele.id === id)
      if (row.products && row.products.length) {
        this.dialogTitle = row.name
        this.skuQuantity = row.quantity
        this.skuList = row.products
        this.dialogVisible = true
      }
    },
    skuSpanMethod({ row, columnIndex }) {
      if (row.children && row.children.length) {
        if (columnIndex === 0) {
          return [1, 3]
        } else {
          return [0, 0]
        }
      }
    },
    Picturedetails(row){
      this.dialogVisible1 = true
      console.log(row
      )
    },
    handleSkuQuantityChange(row) {
      const spu = this.skuList.find(ele => ele.children.some(child => child.id === row.id))
      const qty = spu.children.reduce((acc, cur) => {
        return acc + (cur.id === row.id ? 0 : cur.quantity)
      }, 0)
      if (this.skuQuantity - qty <= row.quantity) {
        this.$nextTick(() => {
          row.quantity = this.skuQuantity - qty
        })
      }
    }
  }
}
</script>

<style>

</style>
